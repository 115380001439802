import React from 'react'
import { Input as CInput, InputProps as CInputProps } from '@chakra-ui/react'
import classnames from 'classnames'
import styles from './Input.module.scss'

export interface InputProps extends CInputProps {}

export const Input: React.FC<InputProps> = ({ className, ...props }) => (
  <CInput className={classnames(styles.container, className)} {...props} />
)
