import { isEmpty } from 'lodash'
import { IncidentItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { FiltersState } from '../../../context/FiltersContextProvider'

export const filterIncidents = (
  items: IncidentItem[],
  { incidentTypeIDs = [], severityLevels = [], incidentLocations = [] }: FiltersState,
) => {
  return items.filter((item) => {
    const fitIncidentTypes = isEmpty(incidentTypeIDs) ? true : incidentTypeIDs.includes(item.IncidentTypeID)
    const fitSeverityLevels = isEmpty(severityLevels) ? true : severityLevels.includes(item.SeverityLevel)
    const fitLocations = isEmpty(incidentLocations) ? true : incidentLocations.includes(item.Address)
    return fitIncidentTypes && fitSeverityLevels && fitLocations
  })
}
