import { AssetItemDB, IncidentItemDB, IncidentTypeItemDB, SeverityItemDB } from './BigQueryEventsClient.types.db'

export enum TargetType {
  Asset = 'Asset',
  Incident = 'Incident',
}

export interface LocationTarget {
  _targetType: TargetType
}

type Timestamp = string

export interface AssetItem extends Omit<AssetItemDB, 'Photo'>, LocationTarget {}

export interface IncidentItem extends Omit<IncidentItemDB, 'IncidentDateTime' | 'Photo'>, LocationTarget {
  IncidentDateTime: Timestamp
}

export interface IncidentTypeItem extends IncidentTypeItemDB {}

export interface SeverityItem extends SeverityItemDB {}
