import React from 'react'
import { type IconBaseProps } from 'react-icons'
import { FaCheckSquare, FaMinusSquare, FaRegSquare } from 'react-icons/fa'
import { Colors } from '@/styles/colors'
import styles from './TreeList.module.scss'

export type CheckBoxVariant = 'all' | 'none' | 'some'

interface CheckBoxIconProps extends IconBaseProps {
  variant: CheckBoxVariant
  color?: keyof typeof Colors
  size?: number
}

export const CheckBoxIcon: React.FC<CheckBoxIconProps> = ({ variant, size = 16, color = Colors.Blue, ...rest }) => {
  switch (variant) {
    case 'all':
      return (
        <div className={styles.checkBoxIconWrapper} style={{ marginRight: '2px' }}>
          <FaCheckSquare className={styles.checkBoxIcon} color={color} size={size} {...rest} />
        </div>
      )
    case 'none':
      return <FaRegSquare color={color} size={size} {...rest} />
    case 'some':
      return (
        <div className={styles.checkBoxIconWrapper} style={{ marginRight: '2px' }}>
          <FaMinusSquare className={styles.checkBoxIcon} color={color} size={size} {...rest} />
        </div>
      )
    default:
      return null
  }
}
