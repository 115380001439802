import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'

export const QUERY_KEY_INCIDENT_LOCATIONS = 'incidents-locations'

export const useIncidentLocationsData = () => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_INCIDENT_LOCATIONS],
    queryFn: () => Api.mapEvents.getIncidentLocations(),
    placeholderData: {},
    staleTime: Infinity,
  })

  return { data, incidentLocationsData: data, ...query }
}
