import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { find, isEmpty } from 'lodash'
import { AssetItem, IncidentItem, IncidentTypeItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { MapDataDrawer, RenderAssetsItem, RenderIncidentsItem } from '../MapView/MapDataDrawer'
import { filterIncidents } from '../components/MapDemoControls/utils/filterIncidents'
import { useAssetsData } from '../hooks/useAssetsData'
import { useDevModeLocalCaching } from '../hooks/useDevModeLocalCaching'
import { useIncidentLocationsData } from '../hooks/useIncidentLocationsData'
import { useIncidentTypesData } from '../hooks/useIncidentTypesData'
import { useIncidentsData } from '../hooks/useIncidentsData'
import { useFiltersContext } from './FiltersContextProvider'
import { useMapContext } from './MapContextProvider'

interface MapContextData {
  incidentsData: IncidentItem[]
  incidentTypesData: IncidentTypeItem[]
  activeTarget: AssetItem | IncidentItem | null
  setActiveTarget(target: MapContextData['activeTarget']): void
  renderIncidents(incidents: IncidentItem[]): void
}

const MapEventsContext = React.createContext<MapContextData>({
  incidentsData: [],
  incidentTypesData: [],
  renderIncidents: () => null,
  activeTarget: null,
  setActiveTarget: () => null,
})

export const useMapEventsContext = () => React.useContext(MapEventsContext)

/**
 *  Load events, rendering and interaction
 */
export const MapEventsContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [activeTarget, setActiveTarget] = useState<MapContextData['activeTarget']>(null)
  const { map } = useMapContext()
  const { hasFilters, filters } = useFiltersContext()

  useDevModeLocalCaching()

  const { assetsData } = useAssetsData()
  const { incidentsData } = useIncidentsData({ enabled: true })
  const { incidentTypesData } = useIncidentTypesData()

  const mapDrawer = useMemo(() => (map ? new MapDataDrawer().setMap(map) : new MapDataDrawer()), [map])

  const onAssetClick = useCallback(
    (id: AssetItem['AssetID']) => {
      const item = find(assetsData!, { AssetID: id })
      if (item) {
        setActiveTarget(item)
      }
    },
    [assetsData],
  )

  const onIncidentClick = useCallback(
    (id: IncidentItem['IncidentID']) => {
      const item = find(incidentsData!, { IncidentID: id })
      if (item) {
        setActiveTarget(item)
      }
    },
    [incidentsData],
  )

  const renderIncidents = useCallback(
    (incidents: IncidentItem[]) => {
      if (mapDrawer.hasMap()) {
        const items: RenderIncidentsItem[] =
          incidents.map((item) => ({
            id: item.IncidentID,
            title: item.Title,
            description: item.Title,
            incidentTypeId: item.IncidentTypeID,
            latitude: item.Latitude,
            longitude: item.Longitude,
          })) || []

        const onItemClick = onIncidentClick.bind(null)
        mapDrawer.renderIncidents({
          items,
          onClick: (item) => {
            onItemClick(item.id as number)
          },
        })
      }
    },
    [mapDrawer, onIncidentClick],
  )

  useEffect(() => {
    if (mapDrawer.hasMap() && !isEmpty(assetsData)) {
      const items: RenderAssetsItem[] =
        assetsData?.map((item) => ({
          id: item.AssetID,
          title: item.AssetName,
          description: item.AssetDescription,
          latitude: item.Latitude,
          longitude: item.Longitude,
        })) || []
      mapDrawer.renderAssets({
        items,
        onClick: (item) => onAssetClick(item.id as number),
      })
    }
  }, [mapDrawer, assetsData, onAssetClick])

  useEffect(() => {
    if (!isEmpty(incidentsData)) {
      // README: Temporary rendering via demo controls
      // const incidents = hasFilters ? filterIncidents(incidentsData!, filters) : incidentsData
      // renderIncidents(incidents)
    }
  }, [mapDrawer, incidentsData, hasFilters, filters, renderIncidents])

  useEffect(() => {
    if (!activeTarget) {
      mapDrawer.resetActiveTargets()
    }
  }, [mapDrawer, activeTarget])

  return (
    <MapEventsContext.Provider
      value={{
        activeTarget,
        incidentsData: incidentsData || [],
        incidentTypesData: incidentTypesData || [],
        setActiveTarget,
        renderIncidents,
      }}
    >
      {children}
    </MapEventsContext.Provider>
  )
}
