import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'
import { IncidentItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'

export const QUERY_KEY_INCIDENT_IMAGE = 'incident-image'

export const useIncidentImageData = ({ link }: { link: IncidentItem['Hyperlink'] | undefined }) => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_INCIDENT_IMAGE, link],
    queryFn: () => Api.mapEvents.getIncidentImage({ link }),
    enabled: !!link,
  })

  return { data, ...query }
}
