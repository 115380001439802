export const getColorScale = ({
  value,
  sourceRangeMin,
  sourceRangeMax,
  targetRangeMin,
  targetRangeMax,
}: Record<'value' | 'sourceRangeMin' | 'sourceRangeMax' | 'targetRangeMin' | 'targetRangeMax', number>): number => {
  const targetRange = targetRangeMax - targetRangeMin
  const sourceRange = sourceRangeMax - sourceRangeMin
  return Math.floor(((value - sourceRangeMin) * targetRange) / sourceRange + targetRangeMin)
}
