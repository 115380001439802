import React, { useRef } from 'react'
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader } from '@chakra-ui/react'
import styles from './RightDrawer.module.scss'

interface RightDrawerProps {
  headerTitle?: string
  contentBody: JSX.Element
  onClose(): void
}

export const RightDrawer: React.FC<RightDrawerProps> = ({ headerTitle, contentBody, onClose }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  return (
    <div className={styles.container}>
      <div className={styles.drawerContainer} ref={containerRef} />
      <Drawer
        isOpen={true}
        placement="right"
        size="md"
        onClose={onClose}
        isFullHeight={false}
        portalProps={{ containerRef }}
      >
        <DrawerContent className={styles.drawerContent} style={{ top: 'initial' }}>
          <DrawerHeader className={styles.header}>
            <DrawerCloseButton position="relative" top="auto" />
            <div className={styles.title}>{headerTitle}</div>
          </DrawerHeader>
          <DrawerBody className={styles.body}>{contentBody}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  )
}
