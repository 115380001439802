import React, { useState } from 'react'
import { isEmpty, isEqual } from 'lodash'
import { Button } from '@/components/common/Button'
import { ErrorBoundary } from '@/components/common/ErrorBoundary'
import { FiltersState, useFiltersContext } from '../../../context/FiltersContextProvider'
import { BasePanel } from '../BasePanel'
import { FilterByIncidentType } from './FilterByIncidentType'
import { FilterByLocation } from './FilterByLocation'
import { FilterBySeverity } from './FilterBySeverity'
import styles from './FiltersPanel.module.scss'
import { FooterControls } from './FooterControls'

export const FiltersPanel: React.FC<{ closePanel(): void }> = ({ closePanel }) => {
  const { hasFilters, filters, applyFilters, resetFilters } = useFiltersContext()
  const [incidentTypeIDs, setIncidentTypeIDs] = useState<FiltersState['incidentTypeIDs']>(filters.incidentTypeIDs)
  const [severityLevels, setSeverityLevels] = useState<FiltersState['severityLevels']>(filters.severityLevels)
  const [incidentLocations, setIncidentLocations] = useState<FiltersState['incidentLocations']>(
    filters.incidentLocations,
  )

  const onApply = () => {
    const newFilters: Partial<FiltersState> = {}
    if (!isEqual(filters.incidentTypeIDs, incidentTypeIDs)) {
      newFilters.incidentTypeIDs = incidentTypeIDs
    }
    if (!isEqual(filters.severityLevels, severityLevels)) {
      newFilters.severityLevels = severityLevels
    }
    if (!isEqual(filters.incidentLocations, incidentLocations)) {
      newFilters.incidentLocations = incidentLocations
    }
    if (!isEmpty(newFilters)) {
      applyFilters(newFilters)
    }
    closePanel()
  }

  const onCancel = () => {
    setIncidentTypeIDs(filters.incidentTypeIDs)
    setSeverityLevels(filters.severityLevels)
    setIncidentLocations(filters.incidentLocations)
    closePanel()
  }

  const onReset = () => {
    resetFilters()
    closePanel()
  }

  return (
    <BasePanel
      className={styles.container}
      title="Incident Filter"
      controls={hasFilters ? <Button label="Reset" onClick={onReset} /> : null}
    >
      <div className={styles.filtersList}>
        <ErrorBoundary>
          <FilterByIncidentType selectedIncidentTypeIDs={incidentTypeIDs} setIncidentTypeIDs={setIncidentTypeIDs} />
        </ErrorBoundary>
        <ErrorBoundary>
          <FilterBySeverity selectedSeverityLevels={severityLevels} setSeverityLevels={setSeverityLevels} />
        </ErrorBoundary>
        <ErrorBoundary>
          <FilterByLocation selectedIncidentLocations={incidentLocations} setIncidentLocations={setIncidentLocations} />
        </ErrorBoundary>
      </div>
      <FooterControls className={styles.footer} onApply={onApply} onCancel={onCancel} />
    </BasePanel>
  )
}
