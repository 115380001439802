import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'

export const QUERY_KEY_SEVERITY_LEVELS = 'severity-levels'

export const useSeverityLevelsData = () => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_SEVERITY_LEVELS],
    queryFn: () => Api.mapEvents.getSeverityLevels(),
    placeholderData: [],
    staleTime: Infinity,
  })

  return {
    data,
    severityLevelsData: data || [],
    ...query,
  }
}
