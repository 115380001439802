import React from 'react'
import classnames from 'classnames'
import { Icon, IconProps, IconSize } from '@/components/common/Icon'
import styles from './ButtonIcon.module.scss'

interface ButtonIconProps {
  icon: IconProps['name']
  disabled?: boolean
  isActive: boolean
  onClick(): void
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({ icon, isActive, disabled, onClick }) => {
  return (
    <div
      className={classnames(styles.container, { [styles.disabled]: disabled, [styles.active]: isActive })}
      onClick={onClick}
    >
      <Icon name={icon} size={IconSize.s32} />
    </div>
  )
}
