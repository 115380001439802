import { IncidentTypeItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { Colors } from '@/styles/colors'

const INCIDENT_TYPE_COLOR: Record<IncidentTypeItem['IncidentTypeID'], string> = {
  '1': '#EC281C',
  '2': '#F17833',
  '3': '#d25b5b',
  '4': '#8465DE',
  '5': '#D8DB35',
}

export const getIncidentTypeIdColor = (typeId: IncidentTypeItem['IncidentTypeCategory']) => {
  const rootTypeId = parseInt(typeId)
  return INCIDENT_TYPE_COLOR[rootTypeId] || Colors.White
}
