import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { IncidentItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { enrichColorField } from '../utils/getNormalizedLocations'

const MAX_LIMIT = 50
const TIME_PERIOD_SEC = 3

const runReadAndProcessItems = async ({
  limit,
  offset,
  incidents,
}: {
  limit: number
  offset: number
  incidents: IncidentItem[]
}): Promise<{ items: IncidentItem[] }> => {
  const items = incidents.slice(offset, offset + limit)
  return { items: enrichColorField(items) }
}

export const useIncidentsDemoLocations = ({
  incidents,
  initialDataRender,
}: {
  incidents: IncidentItem[]
  initialDataRender?: boolean
}) => {
  const [isActiveProcess, setIsActive] = useState(false)
  const [offset, setOffset] = useState(0)
  const [locations, setLocations] = useState<IncidentItem[]>([])

  const clearLocations = useCallback(() => {
    setOffset(0)
    setLocations([])
  }, [])

  // Initial render all events OR !!! --- ADD BUTTON RUN ALL
  useEffect(() => {
    if (!isEmpty(incidents) && initialDataRender) {
      setLocations(incidents)
    }
  }, [incidents, initialDataRender])

  useEffect(() => {
    const readMessages = ({ offset }: { offset: number }) => {
      try {
        runReadAndProcessItems({
          limit: MAX_LIMIT,
          offset,
          incidents,
        }).then(({ items = [] }: Awaited<ReturnType<typeof runReadAndProcessItems>>) => {
          setLocations((locations) => {
            const newLocations = locations.concat(items)
            if (newLocations.length < incidents.length) {
              setOffset(newLocations.length)
            } else {
              setIsActive(false)
            }
            return newLocations
          })
        })
      } catch (e) {
        setIsActive(false)
      }
    }

    let interval: NodeJS.Timeout
    if (isActiveProcess) {
      interval = setInterval(() => readMessages({ offset }), TIME_PERIOD_SEC * 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [isActiveProcess, offset, incidents])

  return {
    locations,
    isActiveProcess,
    setIsActive,
    clearLocations,
  }
}
