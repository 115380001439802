import React, { useEffect } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { Button, ButtonGroup } from '@/components/common/Button'
import { useFiltersContext } from '../../context/FiltersContextProvider'
import { useMapEventsContext } from '../../context/MapEventsContextProvider'
import { DatasetProgress } from './DatasetProgress'
import styles from './MapDemoControls.module.scss'
import { useIncidentsDemoLocations } from './hooks/useIncidentsDemoLocations'
import { filterIncidents } from './utils/filterIncidents'

export const MapDemoControls: React.FC<{ className: string }> = ({ className }) => {
  const { incidentsData, renderIncidents } = useMapEventsContext()
  const { filters, hasFilters } = useFiltersContext()

  const { isActiveProcess, locations, setIsActive, clearLocations } = useIncidentsDemoLocations({
    incidents: incidentsData!,
    initialDataRender: true,
  })

  const onStart = () => setIsActive(true)
  const onStop = () => setIsActive(false)
  const onCleanUp = () => clearLocations()

  useEffect(() => {
    if (!isEmpty(incidentsData)) {
      const filteredLocations = hasFilters ? filterIncidents(locations, filters) : locations
      renderIncidents(filteredLocations)
    }
  }, [renderIncidents, locations, incidentsData, filters, hasFilters])

  const count = incidentsData?.length
  const isDisabledRun = !incidentsData?.length
  const isDisabledClean = isActiveProcess || !locations?.length

  return (
    <div className={classNames(styles.container, className)}>
      <ButtonGroup gap="2">
        {!isActiveProcess ? (
          <Button label="Run Demo" isDisabled={isDisabledRun} onClick={onStart} />
        ) : (
          <Button label="Stop" onClick={onStop} />
        )}
        {isActiveProcess && <DatasetProgress count={count} total={locations.length} />}
        <Button label="Cleanup" isDisabled={isDisabledClean} onClick={onCleanUp} />
      </ButtonGroup>
    </div>
  )
}
