import React, { useCallback, useMemo } from 'react'
import _, { orderBy, uniq } from 'lodash'
import { Icon, IconProps } from '@/components/common/Icon'
import { TreeList, type TreeListProps } from '@/components/common/TreeList'
import { IncidentTypeItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { Colors } from '@/styles/colors'
import { useIncidentTypesData } from '../../../hooks/useIncidentTypesData'
import { getIncidentTypeIdColor } from '../../utils/getIncidentTypeColor'
import styles from './common.module.scss'
import { FilterHeader } from './components/FilterHeader'
import { useFilterOpenProps } from './hooks/useFilterOpen'

interface FilterByIncidentTypeProps {
  selectedIncidentTypeIDs: string[]
  setIncidentTypeIDs(ids: FilterByIncidentTypeProps['selectedIncidentTypeIDs']): void
}

const CategoryIcon: React.FC<IconProps> = ({ name }) => <Icon name={name} size={16} fillColor={Colors.Neutral_90} />

const CATEGORY_ICON_COLORS: Record<IncidentTypeItem['IncidentTypeID'], { label: React.ReactNode; color: string }> = {
  '1': { label: <CategoryIcon name="ExplosionIcon" />, color: getIncidentTypeIdColor('1') },
  '2': { label: <CategoryIcon name="BombIcon" />, color: getIncidentTypeIdColor('2') },
  '3': { label: <CategoryIcon name="CrimeIcon" />, color: getIncidentTypeIdColor('3') },
  '4': { label: <CategoryIcon name="MegaphoneIcon" />, color: getIncidentTypeIdColor('4') },
  '5': { label: <CategoryIcon name="EngineWarningIcon" />, color: getIncidentTypeIdColor('5') },
}

export const FilterByIncidentType: React.FC<FilterByIncidentTypeProps> = ({
  selectedIncidentTypeIDs,
  setIncidentTypeIDs,
}) => {
  const { isOpen, toggleFilter } = useFilterOpenProps(true)
  const { incidentTypesData, isFetching } = useIncidentTypesData()

  const { categoriesData, totalItems } = useMemo(() => getIncidentTypeGroups(incidentTypesData), [incidentTypesData])

  const onNodeSelect: TreeListProps['onNodeSelect'] = useCallback(
    ({ element, isSelected, isBranch }) => {
      if (isSelected) {
        const addedIds = (isBranch ? (element.children as TreeListProps['selectedIds']) : [element.id]) as string[]
        const newIds = uniq([...selectedIncidentTypeIDs, ...addedIds])
        setIncidentTypeIDs(newIds)
      } else {
        const removedIds = (isBranch ? (element.children as TreeListProps['selectedIds']) : [element.id]) as string[]
        const newIds = selectedIncidentTypeIDs.filter((id) => !removedIds.includes(id))
        setIncidentTypeIDs(newIds)
      }
    },
    [selectedIncidentTypeIDs, setIncidentTypeIDs],
  )

  return (
    <div className={styles.filterSection}>
      <FilterHeader
        title="Incident Type"
        isCollapsed={isOpen}
        selectedCount={selectedIncidentTypeIDs.length}
        totalCount={totalItems}
        onCollapse={toggleFilter}
      />
      {isOpen && (
        <TreeList
          className={styles.filtersTreeList}
          data={categoriesData}
          isLoading={isFetching}
          selectedIds={selectedIncidentTypeIDs}
          onNodeSelect={onNodeSelect}
          withSearch
          renderNodeIcon={({ iconId }) => {
            const { color, label } =
              typeof iconId === 'string' ? CATEGORY_ICON_COLORS[iconId] : { color: '', label: '' }
            if (!color && !label) {
              return null
            }
            return (
              <div
                style={{
                  background: color,
                  color: 'black',
                  width: '100%',
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                {label}
              </div>
            )
          }}
        />
      )}
    </div>
  )
}

const getIncidentTypeGroups = (incidentTypes: IncidentTypeItem[]) => {
  const validCategories = incidentTypes.filter((item) => !!item.IncidentTypeName)
  const incidentTypeNameIDhash = incidentTypes
    .filter((item) => !item.IncidentTypeName)
    .reduce<Record<IncidentTypeItem['IncidentTypeCategory'], IncidentTypeItem['IncidentTypeID']>>(
      (acc, item) => ({
        ...acc,
        [item.IncidentTypeCategory]: item.IncidentTypeID,
      }),
      {},
    )

  const categories = _.chain(validCategories)
    .orderBy((item) => parseInt(item.IncidentTypeID), 'asc')
    .groupBy((item) => item.IncidentTypeCategory)
    .value()

  const categoriesData: TreeListProps['data'] = Object.keys(categories).map((categoryName) => ({
    name: categoryName,
    metadata: { iconId: incidentTypeNameIDhash[categoryName] },
    children: orderBy(categories[categoryName], (item) => item.IncidentTypeID).map((item) => ({
      ...item,
      name: item.IncidentTypeName,
      id: item.IncidentTypeID,
    })),
  }))

  return {
    categories,
    categoriesData,
    categoryNames: Object.keys(categories),
    totalItems: validCategories.length,
  }
}
