import { useEffect, useMemo } from 'react'
import { QueryObserver, useQueryClient } from '@tanstack/react-query'
import { Storage } from '@/core/services/Storage'
import { Env } from '@/utils/env'
import { QUERY_KEY_ASSETS } from './useAssetsData'
import { QUERY_KEY_INCIDENT_LOCATIONS } from './useIncidentLocationsData'
import { QUERY_KEY_INCIDENT_TYPES } from './useIncidentTypesData'
import { QUERY_KEY_INCIDENTS } from './useIncidentsData'
import { QUERY_KEY_SEVERITY_LEVELS } from './useSeverityLevelsData'

const storage = new Storage('map-events-data')
const setLocallyCachedData = (queryKey: string, data: any, onError?: (e: unknown) => void) =>
  storage.setValue(queryKey, data, onError)
const getLocallyCachedData = (queryKey: string): any => storage.getValue(queryKey)

const getDate = () => new Date().getDate()

if (Env.isDev()) {
  Object.assign(window, { __appReactQueryStorage: storage })
}

const useQueryKeyCacheWorker = (queryKey: string) => {
  const client = useQueryClient()
  const observer = useMemo(() => {
    const observer = new QueryObserver(client, { queryKey: [queryKey] })
    return observer
  }, [client])

  useEffect(() => {
    if (Env.isDev()) {
      Object.assign(window, { __appReactQueryClient: client })

      if (!observer.hasListeners()) {
        observer.subscribe((query) => {
          const onError = () => {
            if (Array.isArray(query.data)) {
              setLocallyCachedData(queryKey, query.data.slice(0, 1000))
            }
          }
          query.isSuccess && setLocallyCachedData(queryKey, query.data, onError)
        })
      }

      const data = getLocallyCachedData(queryKey)
      if (data) {
        client.cancelQueries({ queryKey: [queryKey] })
        client.setQueryData([queryKey], data)
      }
    }
  }, [client, observer])
}

/**
 * Using to force cache in LS, to avoid do each time requests to BQ data
 */
export const useDevModeLocalCaching = () => {
  useQueryKeyCacheWorker(QUERY_KEY_ASSETS)
  useQueryKeyCacheWorker(QUERY_KEY_INCIDENT_TYPES)
  useQueryKeyCacheWorker(QUERY_KEY_INCIDENTS)
  useQueryKeyCacheWorker(QUERY_KEY_SEVERITY_LEVELS)
  useQueryKeyCacheWorker(QUERY_KEY_INCIDENT_LOCATIONS)

  useEffect(() => {
    const date = getDate()
    if (String(storage.getValue('date')) !== String(date)) {
      storage.clear()
      storage.setValue('date', date)
    }
  }, [])
}
