import { useState } from 'react'

export const useFilterOpenProps = (initialOpen = false) => {
  const [isOpen, setOpen] = useState(initialOpen)
  const toggleFilter = () => setOpen((open) => !open)

  return {
    isOpen,
    setOpen,
    toggleFilter,
  }
}
