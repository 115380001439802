import React from 'react'
import { FiltersContextProvider } from './FiltersContextProvider'
import { MapContextProvider } from './MapContextProvider'
import { MapEventsContextProvider } from './MapEventsContextProvider'

export const ContextsProviders: React.FC<React.PropsWithChildren> = ({ children }) => (
  <MapContextProvider>
    <FiltersContextProvider>
      <MapEventsContextProvider>{children}</MapEventsContextProvider>
    </FiltersContextProvider>
  </MapContextProvider>
)
