import { useEffect, useRef, useState } from 'react'
import { MapManager } from './MapManager'

export const useMapbox = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [map, setMap] = useState<MapManager | null>(null)

  useEffect(() => {
    if (ref.current && !ref.current.hasChildNodes()) {
      const instance = new MapManager({ containerElement: ref.current })
      instance.init().then((map) => setMap(map))
    }
  }, [ref.current])

  return {
    ref,
    map,
  }
}
