import React, { useMemo } from 'react'
import classnames from 'classnames'
import { isEmpty, reject } from 'lodash'
import TreeView, {
  INode,
  ITreeViewOnNodeSelectProps,
  type ITreeViewProps,
  NodeId,
  flattenTree,
} from 'react-accessible-treeview'
import { BsCaretDownFill } from 'react-icons/bs'
import { Loader } from '../Loader'
import { CheckBoxIcon } from './CheckBoxIcon'
import { FilterSearch } from './FilterSearch'
import styles from './TreeList.module.scss'

type ExtraProps = Record<'iconId' | string, string | number | boolean | null> // & { iconId?: boolean | string | number }
type DataItems = Parameters<typeof flattenTree<ExtraProps>>[0]['children']

export interface TreeListProps {
  className?: ITreeViewProps['className']
  isLoading?: boolean
  data: DataItems
  selectedIds: NodeId[]
  defaultExpandedIds?: ITreeViewProps['defaultExpandedIds']
  withSearch?: boolean
  renderNodeIcon?(args: { element: INode; iconId: boolean | string | number }): React.ReactNode
  onNodeSelect({ element, isSelected, isBranch }: ITreeViewOnNodeSelectProps): void
}

const ArrowIcon = ({ isOpen = false }) => (
  <BsCaretDownFill className={classnames(styles.arrowIconDown, { [styles.iconUp]: isOpen })} size={16} />
)

const matchName = (name: string, searchValue: string) => !!name.toLowerCase().match(searchValue.toLowerCase())

const shouldRenderWithFilter = (element: INode, searchValue: string): boolean => {
  return isEmpty(element.children) ? !!element.name.toLowerCase().match(searchValue.toLowerCase()) : true
}

const filterFlattenData = (data: INode[], searchValue: string): ReturnType<typeof flattenTree> => {
  if (!searchValue) {
    return data
  }

  const excludedIDs: (string | number)[] = []
  const filteredChild = data.filter((item) => {
    if (!isEmpty(item.children)) {
      return true
    }

    const isRoot = item.parent === null
    const isMatch = item.name && matchName(item.name, searchValue)

    if (isMatch || isRoot) {
      return true
    } else {
      excludedIDs.push(item.id)
      return false
    }
  })

  return filteredChild.map((item) => {
    if (!isEmpty(item.children)) {
      item.children = reject(item.children, (id) => excludedIDs.includes(id))
    }
    return item
  })
}

export const TreeList: React.FC<TreeListProps> = ({
  className,
  isLoading,
  defaultExpandedIds,
  selectedIds,
  data,
  withSearch,
  renderNodeIcon,
  onNodeSelect,
}) => {
  const { searchValue, setSearchValue } = FilterSearch.useProps()

  const flattenData = useMemo(() => {
    const flattenItems = flattenTree({ name: '', children: data })
    return filterFlattenData(flattenItems, searchValue)
  }, [data, searchValue])

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      {withSearch && <FilterSearch searchValue={searchValue} setSearchValue={setSearchValue} />}
      <TreeView
        className={classnames(styles.container, className)}
        data={flattenData}
        multiSelect
        propagateSelect
        propagateSelectUpwards
        togglableSelect
        expandedIds={searchValue ? flattenData.map((item) => item.id) : undefined}
        defaultExpandedIds={defaultExpandedIds}
        defaultSelectedIds={selectedIds}
        onNodeSelect={onNodeSelect}
        nodeRenderer={({
          element,
          level,
          isBranch,
          isExpanded,
          isHalfSelected,
          isSelected,
          getNodeProps,
          handleExpand,
          handleSelect,
        }) => {
          const { className: itemClassName, ...itemProps } = getNodeProps({ onClick: handleExpand })
          const onClick: React.MouseEventHandler = (e) => {
            handleSelect(e)
            e.stopPropagation()
          }

          if (!shouldRenderWithFilter(element, searchValue)) {
            return null
          }

          return (
            <div
              {...itemProps}
              className={classnames(itemClassName, styles.itemRow)}
              style={{ marginLeft: 50 * (level - 1) }}
            >
              <div className={styles.itemRowLeft}>
                {isBranch && <ArrowIcon isOpen={isExpanded} />}
                <CheckBoxIcon onClick={onClick} variant={isHalfSelected ? 'some' : isSelected ? 'all' : 'none'} />
                <div className={styles.itemName} onClick={onClick}>
                  {element.name}
                </div>
              </div>
              <div className={styles.itemRowRight}>
                {element.metadata?.iconId && renderNodeIcon && (
                  <div className={styles.itemIcon}>{renderNodeIcon({ element, iconId: element.metadata?.iconId })}</div>
                )}
              </div>
            </div>
          )
        }}
      />
    </>
  )
}
