import React from 'react'
import classnames from 'classnames'
import { Icon } from '@/components/common/Icon'
import { Colors } from '@/styles/colors'
import styles from './FilterHeader.module.scss'

interface FilterHeaderProps {
  className?: string
  title: string
  isCollapsed: boolean
  selectedCount: number
  totalCount: number
  onCollapse(): void
}

export const FilterHeader: React.FC<FilterHeaderProps> = ({
  className,
  title,
  selectedCount,
  totalCount,
  isCollapsed,
  onCollapse,
}) => {
  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.title} onClick={onCollapse}>
        {title}
      </div>
      <div className="flex gap-1">
        <div className={styles.count}>
          {selectedCount}/{totalCount}
        </div>
        <div className={classnames(styles.downIcon, { [styles.isUp]: !isCollapsed })} onClick={onCollapse}>
          <Icon name="ArrowDownIcon" fillColor={Colors.Neutral} />
        </div>
      </div>
    </div>
  )
}
