import React, { useCallback, useState } from 'react'
import { FiltersPanel } from '../panels'
import { PanelType } from './usePanel'

interface PanelResolverProps {
  panel: PanelType | null
  closePanel(): void
}

export { PanelType }

export const usePanel = () => {
  const [panel, setPanel] = useState<PanelType | null>(null)

  const closePanel = useCallback(() => {
    setPanel(null)
  }, [])

  return {
    panel,
    setPanel,
    closePanel,
  }
}

export const PanelResolver: React.FC<PanelResolverProps> = ({ panel, closePanel }) => {
  if (!panel) {
    return null
  }

  switch (panel) {
    case PanelType.Filters:
      return <FiltersPanel closePanel={closePanel} />
    default:
      return null
  }
}
