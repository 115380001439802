import React, { useCallback, useMemo } from 'react'
import { uniq } from 'lodash'
import { TreeList, TreeListProps } from '@/components/common/TreeList'
import { SeverityItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { useSeverityLevelsData } from '../../../hooks/useSeverityLevelsData'
import styles from './common.module.scss'
import { FilterHeader } from './components/FilterHeader'
import { useFilterOpenProps } from './hooks/useFilterOpen'

interface FilterBySeverityProps {
  selectedSeverityLevels: number[]
  setSeverityLevels(values: FilterBySeverityProps['selectedSeverityLevels']): void
}

const ICONS_COLOR: Record<SeverityItem['SeverityID'] | string, { label: string; color: string }> = {
  '1': { label: '3', color: '#EC281C' },
  '2': { label: '2', color: '#D8DB35' },
  '3': { label: '1', color: '#26F6B0' },
}

export const FilterBySeverity: React.FC<FilterBySeverityProps> = ({ selectedSeverityLevels, setSeverityLevels }) => {
  const { isOpen, toggleFilter } = useFilterOpenProps(true)
  const { severityLevelsData, isFetching } = useSeverityLevelsData()

  const severitiesData = useMemo(
    () =>
      severityLevelsData.map((item) => ({
        id: String(item.SeverityID),
        name: item.SeverityName,
        metadata: { iconId: true },
      })) as TreeListProps['data'],
    [severityLevelsData],
  )

  const selectedSeverityIds = selectedSeverityLevels
    .map((id) => severityLevelsData.find((item) => item.SeverityID.toString() === id.toString())?.SeverityID.toString())
    .filter((id) => !!id) as string[]

  const onNodeSelect: TreeListProps['onNodeSelect'] = useCallback(
    ({ element, isSelected }) => {
      if (isSelected) {
        const addedIds = [Number(element.id)] as number[]
        const newIds = uniq([...selectedSeverityLevels, ...addedIds])
        setSeverityLevels(newIds)
      } else {
        const removedIds = [Number(element.id)]
        const newIds = selectedSeverityLevels.filter((id) => !removedIds.includes(id))
        setSeverityLevels(newIds)
      }
    },
    [selectedSeverityLevels],
  )

  return (
    <div className={styles.filterSection}>
      <FilterHeader
        title="Severity/Impact"
        isCollapsed={isOpen}
        selectedCount={selectedSeverityLevels.length}
        totalCount={severityLevelsData.length}
        onCollapse={toggleFilter}
      />
      {isOpen && (
        <TreeList
          className={styles.filtersTreeList}
          isLoading={isFetching}
          data={severitiesData}
          selectedIds={selectedSeverityIds}
          onNodeSelect={onNodeSelect}
          renderNodeIcon={({ element }) => {
            return (
              <div
                style={{
                  background: ICONS_COLOR[element.id].color,
                  color: 'black',
                  width: '100%',
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                {ICONS_COLOR[element.id].label}
              </div>
            )
          }}
        />
      )}
    </div>
  )
}
