import { IncidentItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { getColorScale } from './getColorScale'

const getTimestamp = (dateString: string) => new Date(dateString).valueOf()

export const enrichColorField = (data: IncidentItem[]): (IncidentItem & { color: number[] })[] => {
  const timestamps = data.map((item) => getTimestamp(item.IncidentDateTime))
  const maxTime = Math.max(...timestamps)
  const minTime = Math.min(...timestamps)
  const getGreenColor = (timestamp: number) =>
    getColorScale({
      value: timestamp,
      sourceRangeMin: minTime,
      sourceRangeMax: maxTime,
      targetRangeMin: 255,
      targetRangeMax: 10,
    })

  return data.map((item) => ({
    ...item,
    color: [255, getGreenColor(getTimestamp(item.IncidentDateTime)) || 0, 0],
  }))
}
