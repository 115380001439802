import React, { useCallback, useState } from 'react'
import { isEmpty } from 'lodash'
import { IncidentItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'

export interface FiltersState {
  incidentTypeIDs: IncidentItem['IncidentTypeID'][]
  severityLevels: IncidentItem['SeverityLevel'][]
  incidentLocations: IncidentItem['Address'][]
}

interface FiltersContextData {
  filters: FiltersState
  applyFilters(newFilters: Partial<FiltersState>): void
  resetFilters(): void
  hasFilters: boolean
}

const DEFAULT_FILTERS_STATE: FiltersState = {
  incidentTypeIDs: [],
  severityLevels: [],
  incidentLocations: [],
}

const FiltersContext = React.createContext<FiltersContextData>({
  filters: { ...DEFAULT_FILTERS_STATE },
  applyFilters: () => null,
  resetFilters: () => null,
  hasFilters: false,
})

export const useFiltersContext = () => React.useContext(FiltersContext)

export const FiltersContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [filters, setFilters] = useState<FiltersState>({ ...DEFAULT_FILTERS_STATE })

  const applyFilters = useCallback((newFilters: Partial<FiltersState>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }))
  }, [])

  const resetFilters = useCallback(() => applyFilters({ ...DEFAULT_FILTERS_STATE }), [])

  const hasFilters = !isEmpty(Object.values(filters).flat())

  return (
    <FiltersContext.Provider
      value={{
        filters,
        applyFilters,
        resetFilters,
        hasFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}
