import React from 'react'
import { Button, type ButtonProps } from '@/components/common/Button'

const commonButtonProps: Partial<ButtonProps> = {
  flex: 'auto',
  borderRadius: 'initial',
  height: 'inherit',
}

interface FooterControlsProps {
  className?: string
  onApply(): void
  onCancel(): void
}

export const FooterControls: React.FC<FooterControlsProps> = ({ className, onApply, onCancel }) => {
  return (
    <div className={className}>
      <Button label="Cancel" onClick={onCancel} {...commonButtonProps} />
      <Button label="Apply" type="submit" onClick={onApply} {...commonButtonProps} />
    </div>
  )
}
