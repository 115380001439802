import { IncidentItemDB } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types.db'
import { IncidentLocations } from './incident-locations.types'

export const ADDRESS_DELIMITER = ', '

export function createLocationsList(data: Pick<IncidentItemDB, 'Address' | 'Region'>[]): IncidentLocations {
  const list: Record<string, any> = {}
  data.forEach(({ Address, Region }) => {
    // [City, State, USA] | [State, USA]
    const [country, state, city] = Address.split(ADDRESS_DELIMITER).reverse()

    // README: We have an issue in DB with coords(numbers) in Address
    if (!isNaN(parseInt(country))) {
      return
    }

    list[Region] = list[Region] || {}
    list[Region][country] = list[Region][country] || {}

    if (state) {
      list[Region][country][state] = list[Region][country][state] || []
    }

    if (city) {
      list[Region][country][state].push(city)
    }
  })

  const sortObjectKeys = (obj: IncidentLocations): IncidentLocations => {
    const keys = Object.keys(obj).sort()
    return keys.reduce<IncidentLocations>((acc, key) => {
      if (Array.isArray(obj[key])) {
        acc[key] = obj[key].sort()
        return acc
      } else {
        acc[key] = sortObjectKeys(obj[key] as Record<string, any>) as any
        return acc
      }
    }, {})
  }

  const sortedList = sortObjectKeys(list)
  console.log({ sortedList })

  return sortObjectKeys(list)
}
