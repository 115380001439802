import React from 'react'
import { ButtonGroup, Button as CButton, ButtonProps as CButtonProps } from '@chakra-ui/react'
import classnames from 'classnames'
import styles from './Button.module.scss'

export interface ButtonProps extends CButtonProps {
  label?: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

export { ButtonGroup }

export const Button: React.FC<ButtonProps> = ({ className, label, size = 'sm', type = 'button', ...props }) => (
  <CButton
    className={classnames(
      styles.container,
      { [styles.submit]: type === 'submit', [styles.button]: type === 'button', [styles.reset]: type === 'reset' },
      className,
    )}
    type={type}
    size={size}
    {...props}
  >
    {label}
  </CButton>
)
