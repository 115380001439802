import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { Api } from '@/core/api'

export const QUERY_KEY_INCIDENT_TYPES = 'incident-types'

export const useIncidentTypesData = () => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_INCIDENT_TYPES],
    queryFn: () => Api.mapEvents.getIncidentTypes(),
    placeholderData: [],
    staleTime: Infinity,
  })

  return {
    data,
    incidentTypesData: isEmpty(data) ? [] : data!,
    ...query,
  }
}
