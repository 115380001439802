import React, { useState } from 'react'
import { Input, InputProps } from '@/components/common/Input'
import styles from './FilterSearch.module.scss'

const useProps = () => {
  const [searchValue, setSearchValue] = useState('')

  return {
    searchValue,
    setSearchValue,
  }
}

export const FilterSearch: React.FC<ReturnType<typeof useProps>> & { useProps: typeof useProps } = ({
  searchValue,
  setSearchValue,
}) => {
  const onChange: InputProps['onChange'] = (e) => setSearchValue(e.target.value)

  return (
    <div className={styles.container}>
      <Input type="search" placeholder="Search" onChange={onChange} />
    </div>
  )
}

FilterSearch.useProps = useProps
