import axios from 'axios'
import { mapEvents } from './api.map-events'
// import { reports } from './api.reports'
import { AxiosError, instance, isAxiosError, isCancel } from './instance'

export type { AxiosError }

type AppVersion = {
  buildId: string
  timestamp: string
  GITHUB_SHA: string
  GITHUB_RUN_ID: string
  GITHUB_RUN_NUMBER: string
}

export const Api = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,

  isAxiosError,
  isCancel,

  mapEvents,

  appVersion: async () => {
    const data = await axios
      .get<AppVersion>(`/version.txt?timestamp=${Date.now()}`)
      .then((resp) => resp.data)
      .catch((err) => ({}))

    const buildId = typeof window !== 'undefined' ? window.__NEXT_DATA__?.buildId : ''
    return { ...data, buildId }
  },

  // reports,
}
