import React, { useCallback, useMemo } from 'react'
import { isEmpty, setWith, uniq } from 'lodash'
import { TreeList, type TreeListProps } from '@/components/common/TreeList'
import { IncidentLocations } from '@/pages/api/map-events/incident-locations.types'
import { ADDRESS_DELIMITER } from '@/pages/api/map-events/incident-locations.utils'
import { useIncidentLocationsData } from '../../../hooks/useIncidentLocationsData'
import styles from './common.module.scss'
import { FilterHeader } from './components/FilterHeader'
import { useFilterOpenProps } from './hooks/useFilterOpen'

/**
 * [Region, Country, State, City]
 */
const generateLocationID = (parents: string[], key: string) => [key, ...parents].join(ADDRESS_DELIMITER)

interface FilterByLocationProps {
  selectedIncidentLocations: string[]
  setIncidentLocations(ids: FilterByLocationProps['selectedIncidentLocations']): void
}

export const FilterByLocation: React.FC<FilterByLocationProps> = ({
  selectedIncidentLocations,
  setIncidentLocations,
}) => {
  const { isOpen, toggleFilter } = useFilterOpenProps(true)
  const { incidentLocationsData, isFetching } = useIncidentLocationsData()

  const { list, listIDsHash, count } = useMemo(() => getLocationGroups(incidentLocationsData!), [incidentLocationsData])
  const defaultExpandedIds = list.map((region) => region.id as string)

  const onNodeSelect: TreeListProps['onNodeSelect'] = useCallback(
    ({ element, isBranch, isSelected }) => {
      const parents = element.metadata?.parents || []
      const isRegion = element.metadata?.type === 'Region'
      const isEmptyParents = isEmpty(parents)

      let selectedIds = (isBranch ? (element.children as TreeListProps['selectedIds']) : [element.id]) as string[]
      if (isEmptyParents && isBranch && !isRegion) {
        selectedIds = Object.values(listIDsHash[element.id]).flat()
      } else if (isEmptyParents && isBranch && isRegion) {
        const countries = element.children as string[]
        selectedIds = countries.map((country) => Object.values(listIDsHash[country]).flat()).flat()
      }

      if (isSelected) {
        const newIds = uniq([...selectedIncidentLocations, ...selectedIds])
        setIncidentLocations(newIds)
      } else {
        const newIds = selectedIncidentLocations.filter((id) => !selectedIds.includes(id))
        setIncidentLocations(newIds)
      }
    },
    [selectedIncidentLocations, listIDsHash, setIncidentLocations],
  )

  return (
    <div className={styles.filterSection}>
      <FilterHeader
        title="Locations"
        isCollapsed={isOpen}
        selectedCount={selectedIncidentLocations.length}
        totalCount={count}
        onCollapse={toggleFilter}
      />
      {isOpen && (
        <TreeList
          className={styles.filtersTreeList}
          isLoading={isFetching}
          data={list}
          withSearch={false}
          defaultExpandedIds={defaultExpandedIds}
          selectedIds={selectedIncidentLocations}
          onNodeSelect={onNodeSelect}
        />
      )}
    </div>
  )
}

const getLocationGroups = (data: IncidentLocations) => {
  type T = Record<string, object | string[]>
  let count = 0
  let listIDsHash: Record<string, string[]> = {}
  const createNodes = (props: T, parents: string[]): any => {
    const isArray = Array.isArray(props)
    if (isArray && isEmpty(props)) {
      count++
      const newParents = parents.slice()
      const key = newParents.shift()!
      setWith(listIDsHash, [...parents].reverse(), [generateLocationID(newParents, key)])
    } else if (isArray) {
      count += props.length
      const childIds = props.map((prop) => generateLocationID(parents, prop))
      setWith(listIDsHash, [...parents].reverse(), childIds)
    }
    return isArray
      ? props.map((prop) => ({
          id: generateLocationID(parents, prop),
          name: prop,
          metadata: { parents },
        }))
      : Object.keys(props).map((key) => ({
          id: generateLocationID(parents, key),
          name: key,
          metadata: { parents },
          children: createNodes(props[key] as T, [key, ...parents]),
        }))
  }

  const list: TreeListProps['data'] = Object.keys(data).map((region) => {
    const children = createNodes(data[region], [])
    return {
      id: region,
      name: region,
      metadata: { parent: null, type: 'Region' },
      children: isEmpty(children) ? undefined : children,
    }
  })

  return {
    list,
    listIDsHash,
    count,
  }
}
