export const SOURCE = {
  ASSETS: 'assets-source',
  INCIDENTS: 'incidents-source',
  DEMO_LOCATIONS: 'demo-locations',
}

export const LAYER = {
  ASSETS: 'assets-layer',
  ASSETS_ACTIVE: 'assets-active-layer',
  INCIDENTS: 'incidents-layer',
  INCIDENTS_ACTIVE: 'incidents-active-layer',
  DEMO_LOCATIONS: 'demo-locations',
}

export const TextColor = {
  Neutral: '#B8B5C4',
}

export const PointColor = {
  Red: '#EC281C',
  Violet: '#8465DE',
  Yellow: '#FFD439',
}
