import React from 'react'
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

interface DatasetProgressProps {
  count?: number
  total: number
}

const getPercents = (count: number, currentCount: number): number => {
  const value = Math.round((currentCount / count) * 100)
  return Math.min(value, 100)
}

export const DatasetProgress: React.FC<DatasetProgressProps> = ({ count, total }) => {
  const progress = count ? getPercents(count, total) : undefined
  return (
    <CircularProgress value={progress} size="32px">
      <CircularProgressLabel style={{ fontSize: 10 }}>{progress}%</CircularProgressLabel>
    </CircularProgress>
  )
}
