import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'

export const QUERY_KEY_ASSETS = 'map-assets'

export const useAssetsData = () => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_ASSETS],
    queryFn: () => Api.mapEvents.getAssets(),
    placeholderData: [],
  })

  return { data, assetsData: data, ...query }
}
