import mapbox from 'mapbox-gl'
import { Storage } from '@/core/services/Storage'

export type ChangeEventValue = {
  zoom: number | undefined
  center: mapbox.LngLat | undefined
}

const storage = new Storage('map-view.map-box')

const KYIV_COORDS = {
  lat: 50.34,
  lng: 30.42,
}

export const config = {
  saveView: (view: ChangeEventValue) => storage.setValue('view', view),
  restoreView: () => storage.getValue<ChangeEventValue>('view'),
}

export const getDefaultCoords = () => ({
  center: config.restoreView()?.center || KYIV_COORDS,
  zoom: config.restoreView()?.zoom || 10,
})
