import React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useMapContext } from '../context/MapContextProvider'

export const MapBoxView = () => {
  const { mapRefEl } = useMapContext()

  return (
    <>
      <div id="map-box-container" ref={mapRefEl} style={{ height: 'inherit' }} />
    </>
  )
}
