import React from 'react'
import classnames from 'classnames'
import styles from './AsidePanel.module.scss'

export interface AsidePanelProps {
  className?: string
  position: 'left' | 'right'
}

export const AsidePanel: React.FC<React.PropsWithChildren<AsidePanelProps>> = ({ className, position, children }) => {
  return (
    <>
      <div
        className={classnames(
          styles.container,
          {
            [styles.positionLeft]: position === 'left',
            [styles.positionRight]: position === 'right',
          },
          className,
        )}
      >
        {children}
      </div>
    </>
  )
}
