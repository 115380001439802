import React from 'react'
import { AssetItem, IncidentItem, TargetType } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { useMapEventsContext } from '../../context/MapEventsContextProvider'
import { RightDrawer } from '../RightDrawer'
import { TargetAssetCard } from '../cards/TargetAssetCard'
import { TargetIncidentCard } from '../cards/TargetIncidentCard'

type ActiveTarget = AssetItem | IncidentItem | null

const targetIs = {
  Asset: (target: ActiveTarget): target is AssetItem => target?._targetType === TargetType.Asset,
  Incident: (target: ActiveTarget): target is IncidentItem => target?._targetType === TargetType.Incident,
}

export const RightSidebarDrawer = () => {
  const { activeTarget, setActiveTarget } = useMapEventsContext()

  if (targetIs.Asset(activeTarget)) {
    return (
      <RightDrawer
        headerTitle={activeTarget.AssetName}
        contentBody={<TargetAssetCard target={activeTarget} />}
        onClose={() => setActiveTarget(null)}
      />
    )
  }

  if (targetIs.Incident(activeTarget)) {
    return (
      <RightDrawer
        headerTitle={activeTarget.Title}
        contentBody={<TargetIncidentCard target={activeTarget} />}
        onClose={() => setActiveTarget(null)}
      />
    )
  }

  return null
}
