import { useCallback, useState } from 'react'
import { PanelType } from '../panels/panel.types'

export { PanelType }

export const usePanel = () => {
  const [panel, setPanel] = useState<PanelType | null>()

  const closePanel = useCallback(() => setPanel(null), [])

  return {
    panel,
    setPanel,
    closePanel,
  }
}
