'use client'

import React from 'react'
import { MapBoxView } from './MapView'
import styles from './SituationalAwarenessPage.module.scss'
import { LeftSidebar } from './components/LeftSidebar'
import { MapDemoControls } from './components/MapDemoControls'
import { RightSidebarDrawer } from './components/RightSidebarDrawer'
import { ContextsProviders } from './context/ContextsProviders'

export const SituationalAwarenessPage = () => {
  return (
    <div className={styles.container}>
      <ContextsProviders>
        <MapBoxView /> {/* README: used via "mapRefEl" */}
        <MapDemoControls className={styles.demoPanel} />
        <LeftSidebar />
        <RightSidebarDrawer />
      </ContextsProviders>
    </div>
  )
}
