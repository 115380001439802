import React from 'react'
import { isEmpty } from 'lodash'
import { Icon, IconProps, IconSize } from '@/components/common/Icon'
import { Colors } from '@/styles/colors'
import styles from './TargetDetails.module.scss'

export interface TargetDetailsProps {
  incidentMarkerColor?: string
  incidentTypeName?: string
  date?: string
  addressName?: string
  coords?: { lat: number; lng: number }
  contact?: Partial<{ email: string; name: string; phone: string }>
}

const getCoords = (coords: TargetDetailsProps['coords']) => `${coords?.lat.toFixed(6)}, ${coords?.lng.toFixed(6)}`
const getTimeDate = (date: string) =>
  new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date))

const CellItem: React.FC<
  React.PropsWithChildren<{
    iconName?: IconProps['name']
    incidentColor?: TargetDetailsProps['incidentMarkerColor']
  }>
> = ({ iconName, incidentColor, children }) => (
  <div className={styles.cell}>
    {incidentColor && <div className={styles.marker} style={{ background: incidentColor }} />}
    {iconName && <Icon name={iconName} size={IconSize.s20} fillColor={Colors.Neutral} />}
    {children}
  </div>
)

export const TargetDetails: React.FC<TargetDetailsProps> = ({
  incidentMarkerColor,
  incidentTypeName,
  date,
  addressName,
  coords,
  contact,
}) => (
  <div className={styles.container}>
    {incidentTypeName && (
      <CellItem incidentColor={incidentMarkerColor}>
        <div>{incidentTypeName}</div>
      </CellItem>
    )}
    {date && (
      <CellItem iconName="CalendarIcon">
        <div>{getTimeDate(date)}</div>
      </CellItem>
    )}
    {addressName && (
      <CellItem iconName="LocationIcon">
        <div>{addressName}</div>
      </CellItem>
    )}
    {!isEmpty(coords) && (
      <CellItem iconName="CoordinatesIcon">
        <div>{getCoords(coords)}</div>
      </CellItem>
    )}
    {!isEmpty(contact) && (
      <CellItem iconName="ContactIcon">
        <div>
          <div>{contact.email}</div>
          <div>{contact.name}</div>
          <div>{contact.phone}</div>
        </div>
      </CellItem>
    )}
  </div>
)
